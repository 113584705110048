import Axios from "axios";
let token = window.localStorage.getItem("accessToken") || "";

const axios = Axios.create({
  
  // baseURL:
  //   process.env.NODE_ENV === "development"
  //     ? `http://192.168.0.245:8074/api/v1`
  //     : `https://api.believerssign.com/api/v1`, // testing
  
  baseURL: `https://api.believerssign.com/api/v1`,

  // baseURL: `http://localhost:8074/api/v1`,

  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export default axios;
